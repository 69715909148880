import React from 'react'
import PackageSingleItemEditor from "./PackageSingleItemEditor"
import { Table } from "reactstrap"

const PackageListItemsEditor = (props) => {
    const { items, preference, ...rest } = props
    const totalItems = items.length
    const { storeMode = '' } = { ...preference }

    const _handleEditItem = (item, index) => {
        const { items } = props
        const updatedItems = items.map((_item, _index) => {
            if (_index !== index) return _item

            return item
        })

        props.onChangeItems(updatedItems)
    }

    const _checkDisableRemoveItem = (items) => {
        if (items.length === 2) {
            const hasBranding = items.filter(item => item.productLabel === 'branding')

            if (hasBranding.length === 1) {
                return true
            }
        } else if (items.length === 1) {
            return true
        }

        return false
    }

    return (
        <div className="PackageListItemsEditor">
            <div className="HeadingTitle">Items</div>
            <Table responsive striped>
                <thead className='HeadItems'>
                    <tr>
                        <th className='Actions'>&nbsp;</th>
                        <th>MockUp Front</th>
                        <th>MockUp Back</th>
                        <th>Design Front</th>
                        <th>Design Back</th>
                        <th>Design Sleeves</th>
                        <th>Design Hood</th>
                        <th className='Size'>Size</th>
                        <th>Type</th>
                        <th>Sku Partner</th>
                        <th>Color</th>
                        <th>Item</th>
                        <th className='Quantity'>Quantity</th>
                        {storeMode === "marketplace" && <th className='Actions'>&nbsp;</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        items.map((item, index) => {
                            return (
                                <PackageSingleItemEditor
                                    {...rest}
                                    key={index}
                                    totalItem={totalItems}
                                    index={index}
                                    item={item}
                                    disableRemove={_checkDisableRemoveItem(items)}
                                    onChange={_handleEditItem}
                                />
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default PackageListItemsEditor