import React, { useState, useEffect, useRef } from 'react'
import { getDetailShippingFee, updateShippingFee } from '../../../services/api/ShippingServices'
import GeneralPage from './components/GeneralPage'
import ProductlinePage from './components/ProductlinePage'
import classNames from "classnames"
import { Button, message, Modal } from 'antd'
import { Spinner } from 'reactstrap'
import ObjectInspector from "react-object-inspector"
import humanizeTime from '../../../helpers/time/humanizeTime'
import isMatchVariant from "../../product/helpers/isMatchVariant";
import { searchOrderHistories } from '../../../services/api/OrdersServices'
import moment from 'moment'
import { getLocalData } from '../../../services/StorageServices'

const ShippingFeeEditPage = (props) => {
    const permissionsData = !!getLocalData('permissions') ? Object.keys(getLocalData('permissions')) : []
    const { idCost } = props
    const [tab, _setTab] = useState('productline')
    const [supplier, setSupplier] = useState('')
    const [shipping_plan, setShippingPlan] = useState('')
    const [zone, setZone] = useState('')
    const [store, setStore] = useState('')
    const [allStore, setAllStore] = useState(false)
    const [first_item_price, setFirst_item_price] = useState('')
    const [additional_item_price, setAdditional_item_price] = useState('')
    const [product_line, setProduct_line] = useState('')
    const [listVariant, setListVariant] = useState([])
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState({})
    const [error, setError] = useState('')
    const [showHisory, setShowHistory] = useState(false)
    const [defaultShippingCost, setDefaultShippingCost] = useState({})
    const [history, setHistory] = useState([])

    const oldFirtPrice = useRef()
    const oldAddPrice = useRef()
    const priceItemPrev = useRef()

    useEffect(() => {
        const _fetchDetailShippingFee = async () => {

            try {
                const res = await getDetailShippingFee(idCost)
                const { success, data, message } = res

                if (success) {
                    const { defaultShippingCost, relatedVariants } = data
                    setListVariant(relatedVariants)

                    const { additional_item_price, first_item_price, product_line, shipping_plan, shipping_zone, store, supplier } = defaultShippingCost
                    setDefaultShippingCost(defaultShippingCost)
                    setAdditional_item_price(additional_item_price)
                    setFirst_item_price(first_item_price)
                    oldFirtPrice.current = first_item_price
                    oldAddPrice.current = additional_item_price
                    priceItemPrev.current = relatedVariants
                    setProduct_line(product_line)
                    setShippingPlan(shipping_plan)
                    setZone(shipping_zone)
                    setSupplier(supplier)

                    if (store === "" || !store) setAllStore(true)
                    setStore(store)
                }
                setError(message)
            } catch (error) {
                console.log(error.message || 'Unknown error.')
            } finally {
                setLoading(false)
            }
        }

        _fetchDetailShippingFee()
    }, [loading])


    const newVariants = listVariant.filter(item => {

        if (!!item.change) {
            return item.change
        }
    }).map(i => {
        return {
            product_line_variant: i._id,
            first_item_price: (i.first_item_price !== '' && i.first_item_price !== null && i.first_item_price !== undefined) ? i.first_item_price : first_item_price,
            additional_item_price: (i.additional_item_price !== '' && i.additional_item_price !== null && i.additional_item_price !== undefined) ? i.additional_item_price : additional_item_price,
        }
    })

    const _handleChangeFilter = (data) => {
        const updatedFilter = {
            ...filter,
            ...data
        }

        for (let key in updatedFilter) {
            if (!updatedFilter[key]) delete updatedFilter[key]
        }

        setFilter(updatedFilter)
    }

    const _handleChangeVariants = (id, field, value) => {
        if (isNaN(value) && value !== '-') {
            return
        }

        if(!isNaN(value) && ( value < 0 || value >= 1000 )){
            return
        }

        if(!isNaN(value) && value.length >= 2 && value.indexOf('-') === 0){
            return
        }

        if(value.indexOf('.') !== -1){
            const afterDot = value.substring(value.indexOf('.') + 1);

            if(afterDot.length > 2){
                return
            }
        }
        const newVariants = listVariant.map(item => {
            if (item._id !== id) return item

            const prev = Object.assign({}, ...priceItemPrev.current.filter(i => i._id === id))

            if (field === 'first_item_price') {
                const aaa = {
                    ...item,
                    [field]: value,
                    change: prev.first_item_price === value ? false : true,
                }
                return aaa
            }

            if (field === 'additional_item_price') {
                return {
                    ...item,
                    [field]: value,
                    change: prev.additional_item_price === value ? false : true,
                }
            }
        })
        return setListVariant(newVariants)
    }

    const _handleChangeProductLine = product => {
        setProduct_line(product)
    }

    const _handleChangeFirstPrice = price => {
        if (isNaN(price) && price !== '-') {
            return
        }

        if(!isNaN(price) && ( price < 0 || price >= 1000 )){
            return
        }

        if(!isNaN(price) && price.length >= 2 && price.indexOf('-') === 0){
            return
        }

        if(price.indexOf('.') !== -1){
            const afterDot = price.substring(price.indexOf('.') + 1);

            if(afterDot.length > 2){
                return
            }
        }

        setFirst_item_price(price)
    }

    const _handleChangeAdditional = price => {

        if (isNaN(price) && price !== '-') {
            return
        }

        if(!isNaN(price) && ( price < 0 || price >= 1000 )){
            return
        }

        if(!isNaN(price) && price.length >= 2 && price.indexOf('-') === 0){
            return
        }

        if(price.indexOf('.') !== -1){
            const afterDot = price.substring(price.indexOf('.') + 1);

            if(afterDot.length > 2){
                return
            }
        }

        setAdditional_item_price(price)
    }


    const _handleChangeAddStore = (store) => {
        setStore(store)
    }

    const _handleCheckAllStore = () => {
        setAllStore(!allStore)
    }

    const isMatchVariant = (filter, variant) => {
        const keys = Object.keys(Object.assign({}, filter))
        if (!keys || !keys.length) return true
    
        const {options} = Object.assign({}, variant)
        const vOptions = Array.isArray(options) ? options : []
    
        let matches = 0
    
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
    
            const option = vOptions.find(_option => _option && _option.attribute && _option.attribute._id === key && _option.name === filter[key])
            if (option) {
                matches++
            }
        }
    
        return matches === keys.length
    }

    const _handlePaste = (e, field, variantIndex) => {
        const copyText = e.clipboardData.getData("Text")
        const parseText = copyText.split(/[\r?\n]+/)
        let count = 0
        const newVariants = listVariant.map((variant, index) => {
            if (index < variantIndex || count >= parseText.length) return variant
            if(isMatchVariant(filter,variant)){
                return {
                    ...variant,
                    [field]: parseText[count++],
                    change: true
                }
            }
            return {...variant}
        })

        setListVariant(newVariants)
    }

    const _handleCancelClick = () => {
        _setTab('general')
        setFirst_item_price(oldFirtPrice.current)
        setAdditional_item_price(oldAddPrice.current)
        setListVariant(priceItemPrev.current)
    }

    const _handleUpdateShippingRate = async () => {
        setLoading(true)
        const payload = {
            supplier, shipping_plan: shipping_plan._id, shipping_zone: zone._id, product_line: product_line._id,
            store: allStore ? "" : store, variants: newVariants
        }

        if (oldAddPrice.current !== additional_item_price || oldFirtPrice.current !== first_item_price) {
            payload.first_item_price = first_item_price
            payload.additional_item_price = additional_item_price
        }

        try {
            const res = await updateShippingFee(payload)
            const { success } = res

            if (!success) return message.error('Update shipping fee failed!!')

            message.success('Update successfully!!')
        } catch (error) {
            setError(error.message || 'Unknown error.')
        } finally {
            setLoading(false)
        }

    }

    const _generateContentTab = (key) => {
        switch (key) {
            case 'general':
                return <GeneralPage
                    supplier={supplier}
                    shipping_plan={shipping_plan}
                    zone={zone}
                    store={store}
                    allStore={allStore}
                    handleCheckAllStore={_handleCheckAllStore}
                    handleChangeAddStore={_handleChangeAddStore}
                />
            default:
                return <ProductlinePage
                    first_item_price={first_item_price}
                    additional_item_price={additional_item_price}
                    handleChangeFirstPrice={_handleChangeFirstPrice}
                    handleChangeAdditional={_handleChangeAdditional}
                    shipping_plan={shipping_plan}
                    zone={zone}
                    store={store}
                    handleChangeProductLine={_handleChangeProductLine}
                    product_line={product_line}
                    handleChangeVariants={_handleChangeVariants}
                    newVariant={listVariant}
                    handlePaste={_handlePaste}
                    handleChangeFilter={_handleChangeFilter}
                    filter={filter}
                    handleHistoryClick= {_handleHistoryClick}
                    defaultShippingCost={defaultShippingCost}
                    permissionsData={permissionsData}
                />
        }
    }

    const _handleHistoryClick = async(object_id) => {
        setHistory([])
        try {
            const payload = {
                object_type: "SellerShippingCost",
                object_id: object_id,
                page: 1,
                limit: 10000
            }
            const {data, success, message} = await searchOrderHistories(payload)
            if(!success) throw new Error(message)
            setShowHistory(true)
            setHistory(data.histories || [])
        } catch (error) {
            message.error(error.message)
        }
    }
    const _onCloseHistory = () => {
        setShowHistory(false)
        setHistory([])
    }

    const checkNext = supplier !== '' && shipping_plan !== '' && zone !== '' && (store || allStore)
    const checkSave = !!product_line && first_item_price !== '' && first_item_price != null && first_item_price !== undefined && additional_item_price !== '' && additional_item_price != null && additional_item_price !== undefined && !!newVariants
    if (error) return <i className='text-danger fs-14'>{error}</i>

    return (
        <div className="ShippingFeeEditPage">
            <>
                <div className="d-flex justify-content-between border-bottom">
                    <h1 className="PageTitle">
                        {permissionsData.includes('ffm_shipping_fee_update') ?
                            'Update shipping rate' : 'Shipping rate '
                        }
                    </h1>

                    {tab === 'general' && <Button disabled={!checkNext} onClick={() => _setTab('productline')} className="px-4 rounded mr-3" type="primary">Next</Button>}

                    {tab === 'productline' &&
                        <div className="d-flex justify-content-between">
                            <Button className="px-4 rounded mr-3 btn-secondary" onClick={_handleCancelClick} type="default" >Back</Button>
                            {permissionsData.includes('ffm_shipping_fee_update') &&
                                <Button className="px-4 rounded mr-3" type="primary" onClick={_handleUpdateShippingRate} disabled={!checkSave}>
                                    {
                                        loading && <Spinner color='white' size='sm' className='mr-2' />
                                    }

                                    Save
                                </Button>
                            }
                        </div>
                    }

                </div>
                <div className="TabNewShipping">
                    <ul>
                        <li className={classNames('itemTab', tab === 'general' && 'active')}>General</li>
                        <li className={classNames('itemTab', tab === 'productline' && 'active')}>Productline</li>
                    </ul>
                </div>
                <div className="ShippingFeeEditPages">
                    <div className="SectionInner">
                        {_generateContentTab(tab)}
                    </div>
                </div>
                <Modal
                    visible={showHisory}
                    width={800}
                    title='Shipping Fee History'
                    onCancel={_onCloseHistory}
                    className='shippingFeeHistoryModal'
                    footer={[
                        error && <p key='error' className="text-danger">{error}</p>,
                        <Button className="cancel" key="cancel" onClick={_onCloseHistory} disabled={loading}>Đóng</Button>,
                    ]}
                >
                    {!!history && history.length > 0 ? (<ul>
                        {history.map(h => {
                            const {
                                _id: historyId,
                                event,
                                created_at: createdAt,
                                description,
                                meta, user,
                            } = h
                            const hdata = {...meta, user, date_time: moment(createdAt).format('DD/MM/YYYY hh:mm:ss')}

                            return <li className="card" key={historyId}>
                                <div className="card-header">
                                    <time title={createdAt}>{humanizeTime(createdAt)}</time>
                                </div>
                                <div className="card-body">
                                    <div className="row align-items-baseline mb-3">
                                        <div className="col-4"><code>{event}</code>
                                        </div>
                                        <div className="col-8 text-muted">{description}</div>
                                    </div>
                                    <div className="row align-items-baseline">
                                        <div className="col-4">Value</div>
                                        <div className="col-8">
                                            <ObjectInspector depth={0} patxh="root.value" data={hdata} />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        })
                        }
                    </ul>): (<h6 className='text-danger'>No history data has been saved!!!</h6>)
                    }
                </Modal>
            </>
        </div>
    )
}

export default ShippingFeeEditPage
