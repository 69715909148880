import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip, Checkbox } from 'antd'
import ItemReplaced from './ItemReplaced'


class PackageSingleItemEditor extends Component {
    _handleChangeInput = field => e => {
        const {value} = e.target
        const {index, item, onChangeArtworkData, supplierSku} = this.props
        let newValue = value

        if (!!value && field === 'quantity') {
            newValue = parseInt(value)
        }

        const itemUpdated = Object.assign({}, item, {[field]: newValue})

        if (supplierSku === 'PF') {
            if (field === 'designFront' || field === 'designBack' || field === 'designSleeves' || field === 'designHood') {
                onChangeArtworkData({
                    ...item,
                    [field]: newValue
                })
            }
        }

        this.props.onChange(itemUpdated, index)
    }

    _handleClickRemove = () => {
        const {index, onRemoveItem} = this.props

        onRemoveItem(index)
    }

    render() {
        const { item, index, totalItem, preference, handleDuplicateItem, onChangeCheckBoxIssueReplace, supplierSku } = this.props
        const { storeMode } = preference
        const {
            mockUpFront,
            mockUpBack,
            designFront,
            designBack,
            designSleeves,
            designHood,
            size,
            type,
            color,
            item: itemName,
            quantity,
            productLabel,
            partner_sku,
            level,
            outOfStock,
            issueProductions,
            _id,
        } = item

        const hightLight = !!item.itemHasBeenUpdated ? 'ItemHightLight' : null
        const { reference_number = '', isCheck = false } = Object.assign({}, issueProductions)
        const isInfoIssueReplace = !!reference_number && !["1C", "TCVN"].includes(supplierSku)
        
        return (
            <>
                <tr data-index={index} className={`PackageSingleItemEditor ${index%2 === 0 ? 'dark' : 'light'} ${hightLight}`}>
                    <td rowSpan={issueProductions.length > 0 ? 2 : 1} style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <Button
                            icon={<CloseOutlined />}
                            type='link'
                            className='text-danger'
                            disabled={totalItem === 1}
                            onClick={this._handleClickRemove}
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <div className='position-relative'>
                            <input
                                value={mockUpFront}
                                onChange={this._handleChangeInput('mockUpFront')}
                                placeholder="mockUpFront"
                                className="form-control"
                            />
                            {/* {isInfoIssueReplace &&
                                <div style={{ top: 36, minWidth: '90vW' }} className='position-absolute'>
                                    <span className='text-danger d-block' style={{ fontSize: 13 }}>1 Item needs replacing.</span>
                                    <span style={{ fontSize: 15 }} className='d-block'>Mark these issue items as replaced:</span>
                                    <div className="item-replaced">
                                        <Checkbox
                                            className="mr-1"
                                            checked={isCheck}
                                            onChange={e => onChangeCheckBoxIssueReplace(_id, e.target.checked)}
                                        />
                                        <span>{reference_number}</span>
                                    </div>
                                </div>
                            } */}
                        </div>
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={mockUpBack}
                            onChange={this._handleChangeInput('mockUpBack')}
                            placeholder="mockUpBack"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={designFront}
                            onChange={this._handleChangeInput('designFront')}
                            placeholder="designFront"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={designBack}
                            onChange={this._handleChangeInput('designBack')}
                            placeholder="designBack"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={designSleeves}
                            onChange={this._handleChangeInput('designSleeves')}
                            placeholder="designSleeves"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={designHood}
                            onChange={this._handleChangeInput('designHood')}
                            placeholder="designHood"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={size}
                            onChange={this._handleChangeInput('size')}
                            placeholder="size"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={type}
                            onChange={this._handleChangeInput('type')}
                            placeholder="type"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={partner_sku}
                            onChange={this._handleChangeInput('partner_sku')}
                            placeholder="sku partner"
                            className={`${!!outOfStock ? 'Warning' : ''} form-control`}
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={color}
                            onChange={this._handleChangeInput('color')}
                            placeholder="color"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={itemName}
                            onChange={this._handleChangeInput('item')
                            }
                            placeholder="item"
                            className="form-control"
                        />
                    </td>
                    <td style={{ verticalAlign: isInfoIssueReplace ? 'top' : 'middle' }}>
                        <input
                            value={quantity}
                            disabled={productLabel === 'branding'}
                            onChange={this._handleChangeInput('quantity')}
                            placeholder="quantity"
                            className={`${(!!level && level === 'productvariant') || (item.hasOwnProperty('pack') && item.pack > 1) ? 'Warning' : ''} form-control`}
                        />
                    </td>
                    {storeMode === "marketplace" && <td>
                        <Tooltip title="Duplicate line item">
                            <Button
                                icon={<CopyOutlined />}
                                type='link'
                                className='text-primary'
                                onClick={() => handleDuplicateItem(item)}
                            />
                        </Tooltip>
                    </td>}
                </tr>
                {
                    issueProductions.length > 0 &&
                    <tr className={`PackageSingleItemEditor ${index%2 === 0 ? 'dark' : 'light'} ${hightLight} PackageSingleItemEditorIssues`}>
                        <td colSpan={storeMode === "marketplace" ? 13 : 12}>
                            <div className='position-relative'>
                                <div>
                                    <span className='text-danger d-block' style={{ fontSize: 13 }}>{issueProductions.length} {issueProductions.length === 1 ? 'Item' : 'Items'} needs replacing.</span>
                                    <span style={{ fontSize: 15 }} className='d-block'>Mark these issue items as replaced:</span>
                                    <div className='item-replaced-list'>
                                        {
                                            issueProductions.map((ffmIssueItem, index) => {
                                                return (
                                                    <ItemReplaced key={index} id={_id} item={ffmIssueItem} onChange={onChangeCheckBoxIssueReplace}/>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <div className="item-replaced">
                                        <Checkbox
                                            className="mr-1"
                                            checked={isCheck}
                                            onChange={e => onChangeCheckBoxIssueReplace(_id, e.target.checked)}
                                        />
                                        <span>{reference_number}</span>
                                    </div> */}
                                </div>
                            </div>
                        </td>
                    </tr>
                }
            </>
        )
    }
}

PackageSingleItemEditor.propTypes = {
    item: PropTypes.object.isRequired,
    totalItem: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    disableRemove: PropTypes.bool
}

export default PackageSingleItemEditor
