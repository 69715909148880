import React, { useState } from 'react'
import { Popover, Tag, Tooltip, Button } from 'antd'
import { Link } from 'react-router-dom'
import { ITEMS_STATUS } from '../helpers/PrintFileFilters'
import HistoryUpdateModal from './HistoryUpdateModal'
import PackagePusher from '../package-push/PackagePusher'

const Marketplace = (props) => {
    const { itemNumber, namespace, batch, name, tags, fulfillmentId, rowSpan, barcodeNumbers, fulfillmentItemId, supplier, packageNeedPush, listNotePackages, reload } = props
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [openViewMore, setOpenViewMore] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const isSpecialTag = Array.isArray(tags) && tags.includes('priority-store')
    const orderTemplate = !!itemNumber ? `${name}_${itemNumber}` : name
    const { packageNames = [] } = Object.assign({}, packageNeedPush)
    const noteItem = (Array.isArray(listNotePackages) && listNotePackages.length > 0) ? listNotePackages.find(i => name.includes(i.id)) : {}
    const { note } = Object.assign({}, noteItem)

    const _translateProductionStatus = () => {
        const { productionStatus } = props
        const found = ITEMS_STATUS.find(status => status.value === productionStatus)

        if (found === undefined || found.value === '') {
            return null
        }

        return <Tag color='#6c757d'>{found.name}</Tag>
    }

    const toggleModalHistory = () => {
        setIsOpenModal(!isOpenModal)
    }

    const hide = () => {
        setOpenViewMore(false)
    }

    const handleOpenChange = (newOpen) => {
        setOpenViewMore(newOpen)
    }

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    const renderListBarcodeNumber = () => {
        return (
            <>
                {
                    barcodeNumbers.map(item => {
                        return <span className='d-block'>{item}</span>
                    })
                }
            </>
        )
    }
    const barcodesShow = barcodeNumbers ? barcodeNumbers.length > 2 ? barcodeNumbers.filter((barcodeItem, index) => index < 2) : barcodeNumbers : []

    return (
        <td className='Marketplace' rowSpan={rowSpan}>
            {/* <Link to={`/a/orders-supplier/${fulfillmentId}`} style={{ fontSize: 13, whiteSpace: 'nowrap' }} target='_blank'>{orderTemplate}</Link> */}

            <Tooltip title="Click here to view history update">
                <div className="orderTemplate" onClick={toggleModalHistory}>{orderTemplate}</div>
            </Tooltip>
            <HistoryUpdateModal visible={isOpenModal} toggleModal={toggleModalHistory} fulfillmentItemId={fulfillmentItemId} />
            {
                !!name &&
                <div title="orderNumber" className='orderNumber'>
                    {/* {name} */}
                    <Link to={`/a/orders-supplier/${fulfillmentId}`} style={{ fontSize: 13, whiteSpace: 'nowrap' }} target='_blank'>{name}</Link>
                </div>
            }

            {
                !!namespace &&
                <div title="Namespace" className='Namespace mb-1'>
                    <small>{namespace}</small>
                </div>
            }

            {
                barcodesShow.length > 0 &&
                <div className="barcodeNumbers" style={{ fontSize: '12px', color: '#0062cc' }}>
                    {barcodesShow.join(', ')}

                    {
                        barcodeNumbers.length > 2 &&
                        <Popover
                            overlayClassName="listBarcodeNumbers"
                            content={renderListBarcodeNumber}
                            title="List barcode_number"
                            trigger="click"
                            open={openViewMore}
                            onOpenChange={handleOpenChange}
                            overlayStyle={{
                                width: "100px"
                            }}
                        >
                            <span className="ml-2" style={{ fontSize: '11px', cursor: 'pointer' }}>show more</span>
                        </Popover>
                    }
                </div>
            }

            {_translateProductionStatus()}

            {
                !!batch &&
                <div title="Tên lô" className="BatchInfo">
                    <span><Link to={`/batches/${batch.id}`}>{batch.name}</Link></span>
                </div>
            }
            {
                isSpecialTag && <Tag color="#ff0000">Đặc biệt</Tag>
            }

            {
                !!note && (
                    note.length > 15 ?
                        <Popover
                            placement="topLeft"
                            content={note}
                            overlayClassName='note-order-popover'
                            title={`Note`}
                        >
                            <span className='cursor-pointer'>{`${note.substring(0, 15)}...`}</span>
                        </Popover> : <span style={{fontSize: '14px'}}>{note}</span>
                )
            }

            {
                packageNames.includes(name) &&
                <Button type='primary' onClick={handleOpen}>Push</Button>
            }
            {
                isOpen &&
                <PackagePusher
                    isOpen={isOpen}
                    name={name}
                    onClose={handleClose}
                    fulfillmentId={fulfillmentId}
                    supplier={supplier}
                    reload={reload}
                />
            }
        </td>
    )
}

export default Marketplace