import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import PackageAddressEditor from './PackageAddressEditor'
import PackageListItemsEditor from './PackageListItemsEditor'
import { getItemsPreviews, pushItemToSuplier } from '../../../../services/api/ExportOrderServices'
import { getLocalData } from '../../../../services/StorageServices'
import { message } from "antd"
import { Checkbox, } from 'antd'
import broker from "../../../order/OrderBroker"

const PackagePusher = (props) => {
    const { isOpen, name, onClose, fulfillmentId, supplier: sku_prefix = '', reload } = props
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isPushing, setIsPushing] = useState(false)
    const [previews, setPreviews] = useState({})
    const [force, setForce] = useState(false)
    const [changeArtworkData, setChangeArtworkData] = useState([])
    const [shippingCarrier, setShippingCarrier] = useState('DHL eCommerce')
    const [preference, setPreference] = useState('')

    const { items: itemsToPush = [], needGenMockup, mockupStatus } = previews
    const itemsHaveMockupUrl = itemsToPush && itemsToPush.length > 0 ? itemsToPush.filter(item => item.isMockUpUrl === false) : []

    const _fetchItems = async () => {
        setIsLoading(true)
        setError('')

        try {
            const { success, data, message: messageError } = await getItemsPreviews(fulfillmentId)

            if (!success) {
                throw new Error(messageError)
            }

            const disabledSplit = data.items.filter(item => !!item.outOfStock)
            let errorMessageCustom = ''
            if (data.countryCode && ['BH', 'CY', 'EG', 'IR', 'IQ', 'IL', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'SY', 'TR', 'AE', 'YE'].includes(data.countryCode) && (!data.phone || !data.email)) {
                errorMessageCustom = 'This order is missing email or phone data! Should check again before pushing! '
            }

            const {items = [], preference = {}} = {...data}

            const newData = {
                ...data,
                items: items.map(i => {
                    if (i.hasOwnProperty('ffmIssue')) {
                        return {
                            ...i,
                            ffmIssue: {
                                ...i.ffmIssue,
                                isCheck: true
                            }
                        }
                    }
                    return i
                }) || []
            }

            const hasWarning = newData.items.filter(item => item.level === 'productvariant' || (item.hasOwnProperty('pack') && item.pack > 1))
            if (hasWarning.length > 0) setError('Order using artworks with type as variant, please check quantity again before push.')

            setError(errorMessageCustom + (!!disabledSplit && disabledSplit.length ? disabledSplit[0].outOfStock : ''))
            setIsLoading(false)
            setPreviews(newData)
            setPreference(preference)

        } catch (e) {
            setError(e.message || e)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        _fetchItems()
    }, [])

    const _handleChangePreviews = items => {
        setPreviews(Object.assign({}, items))
    }

    const _handleChangeShippingCarrier = (value) => {
        setShippingCarrier(value)
    }

    const _handleChangeForcePush = (e) => {
        setForce(e.target.checked)
    }

    const _handleClickCancel = e => {
        e.preventDefault()
        onClose()
    }

    const _handleClickPush = async e => {
        e.preventDefault()
        setIsPushing(true)
        setError('')

        const { items = [] } = Object.assign({}, previews)

        try {
            const supliers = [
                {
                    'sku_prefix': '1C',
                    'sheet_url': getLocalData(`exportGgDrv.link.1c`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.1c`, '')
                },
                {
                    'sku_prefix': 'BG',
                    'sheet_url': getLocalData(`exportGgDrv.link.basegift`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.basegift`, '')
                },
                {
                    'sheet_url': getLocalData(`exportGgDrv.link.other`, ''),
                    'sheet_name': getLocalData(`exportGgDrv.name.other`, '')
                },
            ]

            const issueItems = items.map(item => {
                if (item.hasOwnProperty('ffmIssue') && item.ffmIssue.isCheck) return item.ffmIssue._id
            }).filter(Boolean) || []

            const params = {
                supplier_sheets: supliers,
                ...previews,
                force,
                changeArtworkData,
                shipping_carrier: shippingCarrier
            }

            if (sku_prefix !== "1C" && issueItems.length > 0) params.issueItems = issueItems

            const { success, message: error } = await pushItemToSuplier(fulfillmentId, params)

            if (!success) {
                setIsPushing(false)
                setError(error)
                return
            }

            setError('')
            setIsPushing(false)

            message.success('Package push success!')
            broker.publish("TRACKING_UPDATED")
            broker.publish("ORDER_REFRESH_ORDER")
            reload()
            onClose()

        } catch (err) {
            setError(err.message || err)
            setIsPushing(false)
        }
    }

    const _handleChangeItems = items => {
        const updateItems = Object.assign({}, previews, {
            items: items
        })
        setPreviews(Object.assign({}, updateItems))
    }

    const _onChangeArtworkData = (data) => {
        const dataChange = changeArtworkData.filter(item => item._id !== data._id)
        setChangeArtworkData([...dataChange, { ...data }])
        setError('The design of supplier PF has been optimized for production. If you change the link design, this optimal function will not apply.')
    }

    const _removeItem = index => {
        const { items } = previews

        const filtered = items.filter((item, idx) => index !== idx)

        const updateItems = Object.assign({}, previews, {
            items: filtered
        })

        setPreviews(Object.assign({}, updateItems))
    }

    const _handleDuplicateItem = (item) => {
        setPreviews({
            ...previews,
            items: [
                ...previews.items,
                item
            ]
        })
    }

    const _handleCheckBoxIssueReplace = (id, checked) => {
        const { items = [] } = Object.assign({}, previews)

        const newItems = items.map(item => {
            if (item._id === id && item.hasOwnProperty('ffmIssue')) {
                return {
                    ...item,
                    ffmIssue: {
                        ...item.ffmIssue,
                        isCheck: checked
                    }
                }
            }
            return item
        })

        setPreviews({ ...previews, items: newItems })
    }

    return (
        <div className="PackagePusherEditor">
            <Modal className="PackagePusherEditorModal" size="lg" backdrop="static" isOpen={isOpen}>
                <ModalHeader>
                    <div className="Name"><strong>{name}</strong> - <strong>{itemsToPush.length}</strong> items</div>
                </ModalHeader>
                <ModalBody>
                    {
                        !!isLoading && <div className="Loading">Loading...</div>
                    }

                    {
                        (!isLoading && previews !== undefined) &&
                        <div className="Editor">
                            <PackageAddressEditor
                                shippingCarrier={shippingCarrier}
                                previews={previews}
                                onChangePreview={_handleChangePreviews}
                                onChangeShippingCarrier={_handleChangeShippingCarrier}
                                supplierSku={sku_prefix}
                            />

                            {
                                itemsToPush !== undefined && (
                                    <PackageListItemsEditor
                                        supplierSku={sku_prefix}
                                        items={itemsToPush}
                                        onChangeItems={_handleChangeItems}
                                        onChangeArtworkData={_onChangeArtworkData}
                                        onRemoveItem={_removeItem}
                                        preference={preference}
                                        handleDuplicateItem={_handleDuplicateItem}
                                        onChangeCheckBoxIssueReplace={_handleCheckBoxIssueReplace}
                                    />
                                )
                            }
                        </div>
                    }

                    {
                        !!error && <div className="Error text-danger mt-3">{error}</div>
                    }
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="footer-message">
                            {
                                !!needGenMockup && (!mockupStatus || mockupStatus !== 'completed') && itemsHaveMockupUrl.length > 0 ?
                                    <div className="Error text-danger">Generate mockups for designs in progress. You need to check before pushing this order.</div> : ''
                            }
                        </div>
                        <div className="d-flex align-items-center footer-button">
                            <div className="check_force d-flex justify-content-end mr-3">
                                <Checkbox
                                    checked={force}
                                    onChange={_handleChangeForcePush}
                                >
                                </Checkbox>
                                <span className="ml-2">
                                    Do you want to push again?
                                </span>
                            </div>
                            <button className="btn btn-secondary" disabled={isPushing} onClick={_handleClickCancel}>
                                Cancel
                            </button>
                            <button onClick={_handleClickPush} className="btn btn-success ml-3" disabled={isPushing}>
                                {isPushing ? 'Pushing...' : 'Push'}
                            </button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default PackagePusher